import erika from "../src/assets/peopleWebp/erika.webp";
import momo from "../src/assets/peopleWebp/momo.webp";
import natalie from "../src/assets/peopleWebp/natalie.webp";
import vivian from "../src/assets/peopleWebp/vivian.webp";
import jannae from "../src/assets/peopleWebp/janae.webp";
import karla from "../src/assets/peopleWebp/Karla.webp";
import herminia from "../src/assets/peopleWebp/herminia.webp";
import lidia from "../src/assets/peopleWebp/lidia.webp";
import lupita from "../src/assets/peopleWebp/lupita.webp";


export const getData = async () => {
  return [
    {
      _id: "5",
      image: natalie,
      firstName: "Natalie",
      emoji: "🤍",
      role: "Owner / Colorist",
      snippet: "Making hair thrive one head at a time!",
      igLink: "https://www.instagram.com/Hairaffinatalie",
      twLink: "",
      fbLink: "",
      tikLink: ""
    },
    {
      _id: "3",
      image: erika,
      firstName: "Erika",
      emoji: "👩🏽‍🎨",
      role: "Hair Artist",
      snippet: "I love what i do!",
      igLink: "https://www.instagram.com/hair_by_crypticscissors ",
      twLink: "",
      fbLink: "",
      tikLink: ""
    },
    {
      _id: "4",
      image: momo,
      firstName: "Momo",
      emoji: "🤎",
      role: "Hair Stylist",
      snippet: "Let’s transform your hair!",
      igLink: "https://www.instagram.com/_hairbymomo",
      twLink: "",
      fbLink: "",
      tikLink: ""
    },
    {
      _id: "6",
      image: vivian,
      firstName: "Vivian",
      emoji: "💖",
      role: "Hair Stylist",
      snippet: "Being genuinely kind, the world needs more of it.",
      igLink: "https://www.instagram.com/Hair.byviv ",
      twLink: "",
      fbLink: "",
      tikLink: ""
    },
    {
      _id: "7",
      image: jannae,
      firstName: "Jennae",
      emoji: "🤪",
      role: "Hair Stylist",
      snippet: "The party’s here!!!",
      igLink: "https://www.instagram.com/stylesbyjennae",
      twLink: "",
      fbLink: "",
      tikLink: ""
    },
    {
      _id: "8",
      image: karla,
      firstName: "Karla",
      emoji: "🌊",
      role: "Hair Stylist",
      snippet: "I love to make you feel amazing! ",
      igLink: "https://www.instagram.com/hairby_karlaa3",
      twLink: "",
      fbLink: "",
      tikLink: ""
    },
    {
      _id: "9",
      image: lupita,
      firstName: "Lupita",
      emoji: "🖤",
      role: "Lash Artist",
      snippet: "Therapy and lashes, all in one appointment!",
      igLink: "https://www.instagram.com/lashesbylupita ",
      twLink: "",
      fbLink: "",
      tikLink: ""
    },
    {
      _id: "10",
      image: herminia,
      firstName: "Herminia",
      emoji: "👩‍🎨",
      role: "Hair Colorist/Stylist",
      snippet: "Let your personality shine through your hair!",
      igLink: "https://www.instagram.com/hair.minia",
      twLink: "",
      fbLink: "",
      tikLink: ""
    },
    {
      _id: "2",
      image: lidia,
      firstName: "Lidia",
      emoji: "✨",
      role: "Junior Hair Stylist",
      snippet: "Hair is my passion!",
      igLink: "https://www.instagram.com/hairgossip.LA",
      twLink: "",
      fbLink: "",
      tikLink: ""
    },
  ];
};
