import pic1 from  "../src/assets/reviewersWebp/1.webp"
import pic2 from  "../src/assets/reviewersWebp/pic2.webp"
import pic3 from  "../src/assets/reviewersWebp/pic3.webp"
import pic4 from  "../src/assets/reviewersWebp/4.webp"
import pic5 from  "../src/assets/reviewersWebp/5.webp"
import pic6 from  "../src/assets/reviewersWebp/6.webp"
import pic7 from  "../src/assets/reviewersWebp/7.webp"
import pic8 from  "../src/assets/reviewersWebp/8.webp"
import pic9 from  "../src/assets/reviewersWebp/9.webp"
import pic10 from  "../src/assets/reviewersWebp/10.webp"
import pic11 from  "../src/assets/reviewersWebp/11.webp"
import pic12 from  "../src/assets/reviewersWebp/12.webp"
import user from "../src/assets/reviewersWebp/user.webp"
export const getReview = async () => {
  return [
    {
      _id: "1",
      image: pic1,
      name: "Frankie S.",
      review: "I have been seeing Monique for almost two years and she is seriously the BEST balayage stylist literally ever. She has seriously transformed my hair from dark dark brown almost black to the most...",
      date: "7/13/2022",
     
    },
    {
      _id: "2",
      image: pic2,
      name: "Susie C.",
      review: "This is the best salon for your $$. From location,parking,safety protocols and the quality of work, this is the only salon I trust. My hair artist Erika is the best!! Whether you want a classic look...",
      date: "4/23/2021",
     
    },
    {
      _id: "3",
      image: pic3,
      name: "Hilda M.",
      review: "I went for a long overdue hair cut and color. I was impressed by the measures being taken to prevent covid 19. Definitely felt secure the whole time I was there. The color turned out excellent...",
      date: "	8/1/2021",
     
    },
    {
      _id: "4",
      image: pic4,
      name: "Clarissa S.",
      review: "Monique Vallejo has been doing my hair for two years now. I was always scared to get out of my comfort zone and bleach it, but I decided I needed a change and trusted her to help me lighten my hair...",
      date: "8/19/2021",
     
    },
    {
      _id: "5",
      image: pic5,
      name: "Jaquelyn R.",
      review: "I recommend Monique!! She does amazing great work, she is super friendly and makes you feel super welcomed, the energy and environment in the salon is so lovely and actually is quite relaxing...",
      date: "4/23/2021",
     
    },
    {
      _id: "6",
      image: pic6,
      name: "Dana A.",
      review: "Went in last night for a super last minute blow out and style. Didn't think I would find a spot so last minute as I was dreading washing and styling my own thick hair yesterday from a longggg week...",
      date: "	8/1/2021",
     
    },
    {
      _id: "7",
      image: pic7,
      name: "Alena R.",
      review: "Love getting my hair done here at this salon. I get my hair done by Natalie who is also the owner. Always treated with such care, questions are always answered, and I have always been satisfied with...",
      date: "7/13/2022",
     
    },
    {
      _id: "8",
      image: pic8,
      name: "Alexandria V.",
      review: "Best place ever love my hair so much thanx to Patricia holler. My hair was orange before I went to her it looked bad you know us girls be thinking we can do our own hair. I learned it's not always...",
      date: "4/23/2021",
     
    },
    {
      _id: "9",
      image: pic9,
      name: "Prim P.",
      review: "I have been Erika's customer for over 10 years. Because of Pandemic she is now move to Affiniti hair Salon which is a bit far from my house but of course she worths all the driving. Her experience and...",
      date: "	8/1/2021",
     
    },
    {
      _id: "10",
      image: pic10,
      name: "Jennyfer C.",
      review: "This place is amazing. And the hair stylists are even more amazing! They all did excellent work and are the nicest people. I got tape in extensions for my wedding and even if I don't style them they... ",
      date: "10/7/2022",
     
    },
    {
      _id: "11",
      image: pic11,
      name: "Nancy C.",
      review: "My husband has been coming with Erika for many many years now & I have also got my hair color treatments & hair cuts with her too. She always makes you feel so relaxed & explains each step...",
      date: "9/22/2022",
     
    },
    {
      _id: "12",
      image: pic12,
      name: "Prescila S.",
      review: "I've been coming to Affiniti Hair Salon for a little over a year now. Monique V has been my hairstylist throughout the entire time and I swear I will never go to anyone else, but her My hair is a natural...",
      date: "9/9/2022",
     
    },
  ];
};
